<template>
  <validation-observer ref="simpleRules">
    <div>
      <!-- รอดึงข้อมูลสำเร็จ -->
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <!-- รอดึงข้อมูลสำเร็จ -->
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
            <h3 class="font-weight-bolder">
              {{ $t('warehouseInfo') }}
            </h3>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <b-form-select
                v-model="selected"
                :options="options"
                class="w-50 ml-1"
                size="sm"
              />
            </div>
          </div>

          <div class="pl-2 pr-2 pt-1">
            <router-link
              :to="{ name: 'admin-warehouse-section-create' }"
              class="px-2"
            >
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
              >
                <span>+ {{ $t('addWarehouseInfo') }} </span>
              </button>
            </router-link>
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(queue)="data">
                <p>{{ data.item.wide_size && data.item.long_size && data.item.high_size ?
                  Commas((data.item.wide_size * data.item.long_size * data.item.high_size) / 1000000) : 0 }}</p>
              </template>

              <template #cell(customerType)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
                <feather-icon
                  icon="DeleteIcon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="DeleteData(data.item)"
                />
              </template>
              <template #cell(map)="data">
                <img
                  v-if="data.item.map"
                  :src="data.item.map"
                  accept=".jpg, .png, .jpeg"
                  alt="รูปภาพ"
                  class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                >
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-modal
        id="modal-add-new"
        :title="$t('key-21')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="submitAddNew()"
      >
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">{{ $t('customerCode') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCode"
              v-model="customerCode"
              :state="errors.length > 0 ? false : null"
              type="text"
              :placeholder="$t('customerCode')"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="type"
              :options="typeOp"
              :state="errors.length > 0 ? false : null"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="detail">{{ $t('details') }} :</label>
          <b-form-textarea
            id="detail"
            v-model="detail"
            :placeholder="$t('details')"
          />
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <label for="amount">{{ $t('quantity') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('quantity')"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="prefix">Prefix :</label>
          <b-form-input
            id="prefix"
            v-model="prefix"
            type="number"
            placeholder="Prefix"
          />
        </b-form-group>

        <b-form-group>
          <label for="prefixNum">Prefix Number :</label>
          <b-form-input
            id="prefixNum"
            v-model="prefixNum"
            type="number"
            placeholder="Prefix Number"
          />
        </b-form-group>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BOverlay,
  // BLink,
  BFormSelect, BModal, BFormGroup, BFormInput, BFormTextarea, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BTable,
    // BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BOverlay,
    // รอดึงข้อมูลสำเร็จ
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 9,
      totalRows: 0,

      items: [],
      itemsData: [],
      // รอดึงข้อมูลสำเร็จ
      showOver: false,
      search: '',
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'key_name', label: this.$t('number'), thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center',
        },
        {
          key: 'map', label: this.$t('map'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'wide_size', label: this.$t('width'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => this.Commas(value),
        },
        {
          key: 'high_size', label: this.$t('height'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => this.Commas(value),
        },
        {
          key: 'long_size', label: this.$t('length'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', formatter: value => this.Commas(value),
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customerType', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  async mounted() {
    await this.getData('all')
  },
  methods: {
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast(this.$t('key-279'), {
              title: this.$t('key-280'),
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getData(type) {
      this.isActive = type
      // รอดึงข้อมูลสำเร็จ
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
        }
        this.$http.get('/warehouses/getdata', { params })
          .then(response => {
            this.items = response.data.data
            this.totalRows = response.data.total
            // console.log(this.currentPage)
            this.showOver = false
            // console.log(response.data)
          })
          .catch(error => {
            console.log(error)
            this.showOver = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data) {
      // console.log(data._id)
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-warehouse-section-edit', params: { id: data._id } })
    },

    DeleteData(data) {
      this.$bvModal.msgBoxConfirm(this.$t('key-281'), {
        title: this.$t('key-217'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })

        .then(value => {
          if (value) {
            // eslint-disable-next-line no-underscore-dangle
            console.log(data._id)
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            }

            this.showOver = true
            // this.Success('ลบข้อมูล สำเร็จ')
            this.$http.post('/warehouses/deletedata', params).then(() => {
              this.getData()
              this.showOver = true

              this.$bvToast.toast(this.$t('key-282'), {
                title: this.$t('key-280'),
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.showOver = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
